import React from 'react';
import classes from './LastPrice.module.css'
import {useTranslation} from "react-i18next";
import {useGetDashboard} from "../../../../../../query";
import Coin from "../Coin/Coin";
import useGlobal from "../../../../../../Hooks/useGlobal";

const LastPrice = () => {

    const {t} = useTranslation();

    /*const {data, isLoading, error} = useGetDashboard()*/


    const {global} = useGlobal();



    const content = () => {

        /*if (isLoading) {
            return <div className={`width-100 column jc-center ai-center height-100 my-1`}>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
            </div>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (Object.keys(data).length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }*/

        return Object.keys(global?.currencies).map(key => <Coin data={global?.currencies[key]} key={key}/>)


    }

    return (
        <div className={`width-86 ${classes.container} mt-4 mb-4 column jc-center ai-center rounded-8`}>
            {content()}
        </div>
    );
};

export default LastPrice;
