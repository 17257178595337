import React from 'react';
import classes from './Info.module.css'
import MainMenu from "../MainMenu/MainMenu";
import {useGetUserInfo, useGetUserWallet} from "../../../../../../query";
import {BN} from "../../../../../../utils/utils";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";

const Info = () => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const {data, isLoading, error} = useGetUserInfo()


    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError} = useGetUserWallet()

    const totalBalanceHandler = (total) => {

        if (!total) return <div className={`column width-48 ai-end jc-center`}>
            <span className={`${classes.loading} rounded-4 width-100 mb-05`}/>
            <span className={`${classes.loading} rounded-4 width-100 mt-05`}/>
        </div>
        const newObject = [];
        Object.keys(total).forEach((key) => {
            newObject.push( <div className={`row fs-01`} key={key}><span className={`ml-1  fs-0-9`}>{key}</span>  <span >{global?.hideUserBalance ? "*****" : new BN(total[key]).decimalPlaces(global?.currencies[key]?.precision).toFormat()}</span>   </div>)
        });
        return newObject;
    }

    const content = () => {
      if (isLoading || userWalletIsLoading) {
          return <div className={`row jc-between ai-center text-white width-100 mt-2`}>
              <div className={`column width-48 jc-start`}>
                  <span className={`${classes.loading} rounded-4 width-90 mb-05`}/>
                  <span className={`${classes.loading} rounded-4 width-70 mt-05`}/>
              </div>
              <div className={`column width-48 ai-end jc-center`}>
                  <span className={`${classes.loading} rounded-4 width-65 mb-05`}/>
                  <span className={`${classes.loading} rounded-4 width-65 mt-05`}/>
              </div>
          </div>
      }

      else return <div className={`row jc-between ai-center text-white width-100 mt-2`}>
            <div className={`column width-48 jc-start`}>
                <span className={`font-weight-bold fs-04 text-white ${classes.name}`}>{data?.first_name} {data?.last_name}</span>
                <div className={`row jc-start ai-center`}>
                    <span className={`ml-2`}>{t("totalValue")}</span>
                   {/* <Icons
                        iconName="icon-eye flex"
                        iconClass={`cursor-pointer`}
                    />*/}
                </div>

            </div>
            <div className={`column width-48 ai-end jc-center `} onClick={()=>setGlobal({...global, hideUserBalance: !global.hideUserBalance})}>
                {totalBalanceHandler(userWalletData?.total)}
            </div>
        </div>
    }



    return (
        <div className={`${classes.container} height-29 width-100 column jc-start position-relative px-7`}>

            {/*{isLoading ? <span className={`${classes.loading} rounded-4 width-38`}/> :
                <span className={`font-weight-bold fs-04 text-white`}>{data?.first_name} {data?.last_name}</span>
            }


            <div className={`row jc-between ai-center text-white mt-1`}>
                <div className={`row jc-start ai-center`}>
                    <span className={`ml-2`}>ارزش دارایی‌ها</span>
                    <Icons
                        iconName="icon-eye flex"
                        iconClass={`cursor-pointer`}
                    />
                </div>

                <div className={`row jc-end ai-center fs-02`}>
                    {totalBalanceHandler(userWalletData?.total)}
                </div>

            </div>*/}


            {content()}


            <MainMenu/>

        </div>
    );
};

export default Info;
