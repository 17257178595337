import React, {useState} from 'react';
import classes from './ForgetPassword.module.css';
import OtpContainer from "../../../../components/OtpContainer/OtpContainer";
import Button from "../../../../components/Button/Button";
import Loading from "../../../../components/Loading/Loading";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useAuth from "../../../../Hooks/useAuth";
import {forgetPassword} from "../../../../api/auth";
import * as RoutesName from "../../../../Routes/routes";
import toast from "react-hot-toast";
import TextInput from "../../../../components/TextInput/TextInput";
import Icon from "../../../../components/Icon/Icons";
import ScrollBar from "../../../../components/ScrollBar";

const ForgetPassword = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();


    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState({
        otp: {value: "", error: []},
        newPassword: {value: "", error: []},
        confirmNewPassword: {value: "", error: []},
    });

    const [isInputVisible, setIsInputVisible] = useState({
        newPassword: false,
        confirmNewPassword: false,
    });


    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t("TransactionPassword." + e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }

        if (e.target.dataset?.name === "confirmNewPassword" && e.target.value !== input.newPassword.value) {
            errorMessage.push([t('TransactionPassword.wrongPasswordConfirmation')])
        }


        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }

        if (e.target.dataset?.name === "password") {
            prevState.confirmPassword.error = (e.target.value === input.confirmPassword.value || input.confirmPassword.value.length === 0) ? [] : [t('wrongPasswordConfirmation')]
        }

        setInput(prevState)
    }

    const isFormValid = () => {

        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t("TransactionPassword." + key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

    const submit = async (e) => {


        if (!input.otp.value) {
            return toast.error(t("Otp.enterCode"))
            /*return setInput({...input, otp:{...input.otp, error: [`${t("Otp.enterCode")}`] } })*/
        }

        if (input.otp.value.length < 6) {
            /*return setInput({...input, otp:{...input.otp, error: [`${t("Otp.otpCodeMin")}`] } })*/
            return toast.error(t("Otp.otpCodeMin"))
        }

        if (!isFormValid()) return false;


        setLoading(true)

        const forgetpasswordParam = {
            "mobile": auth?.mobile,
            "otp": input.otp.value,
            "new_password": input.newPassword.value,
            "new_password_confirmation": input.confirmNewPassword.value,
        }

        forgetPassword(forgetpasswordParam)
            .then(async (res) => {
                toast.success(t("passwordChangeSuccessfully"))
                navigate(RoutesName.Password)
            }).catch(err => {
            toast.error(err?.response?.data?.message)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <ScrollBar>
            <form onSubmit={(e)=>submit(e)} className={`${classes.container} min-height-100 column jc-between ai-center py-3 px-5`}>



            <OtpContainer input={input} setInput={setInput} isForforgetPassword={true}/>

            <div className={`column jc-center ai-center width-100`}>
                <TextInput
                    value={input.newPassword.value}
                    label={t('TransactionPassword.newPassword')}
                    //placeholder={t('Login.mobilePh')}
                    data-name="newPassword"
                    data-type="input"
                    data-min={8}
                    id="newPassword"
                    labelFor="newPassword"
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.newPassword.error}
                    inputClass={`width-85 my-1`}
                    autoComplete="new-password"
                    type={isInputVisible.newPassword ? "text" : "password"}
                    icon={
                        <Icon
                            iconName={`${isInputVisible.newPassword ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                            onClick={() => setIsInputVisible({
                                ...isInputVisible,
                                newPassword: !isInputVisible.newPassword
                            })}
                        />
                    }
                />
                <TextInput
                    value={input.confirmNewPassword.value}
                    label={t('TransactionPassword.confirmNewPassword')}
                    //placeholder={t('Login.mobilePh')}
                    data-name="confirmNewPassword"
                    data-type="input"
                    //data-min={2}
                    //maxLength="10"
                    id="confirmNewPassword"
                    labelFor="confirmNewPassword"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.confirmNewPassword.error}
                    inputClass={`width-85 my-1`}
                    autoComplete="new-password"
                    type={isInputVisible.confirmNewPassword ? "text" : "password"}
                    icon={
                        <Icon
                            iconName={`${isInputVisible.confirmNewPassword ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                            onClick={() => setIsInputVisible({
                                ...isInputVisible,
                                confirmNewPassword: !isInputVisible.confirmNewPassword
                            })}
                        />
                    }

                />
            </div>




            <Button
                type="button"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading}
                onClick={()=>submit()}
            />
        </form>
        </ScrollBar>
    );
};

export default ForgetPassword;
