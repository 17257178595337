import React from 'react';
import classes from './Network.module.css';
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetCurrencies} from "../../../../../../query";
import CurrencyCard from "../CurrencyCard/CurrencyCard";
import NetworkCard from "../NetworkCard/NetworkCard";
import useGlobal from "../../../../../../Hooks/useGlobal";

const Network = ({type}) => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const {data, isLoading, error} = useGetCurrencies()

    const content = () => {

        if ( type === "deposit_network" && !global?.selected_deposit_coin) {
            return <span className={`my-10`}>{t("Deposit.selectNetwork")}</span>
        }
        if ( type === "withdrawal_network" && !global?.selected_withdrawal_coin) {
            return <span className={`my-10`}>{t("Deposit.selectNetwork")}</span>
        }
        if (isLoading) {
            return <span className={`my-10`}>{t("loading")}</span>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (Object.keys(data?.currencies).length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }
        return <NetworkCard data={type === "withdrawal_network" ? data?.currencies[global?.selected_withdrawal_coin] : data?.currencies[global?.selected_deposit_coin] }/>

        /*return Object.keys(data).map(key => <NetworkCard data={data[key]} key={key}/>)*/
        /*return .map(key => <NetworkCard data={""}/>)*/



       /*
        return data?.filter((f)=> f?.currency2?.code === "IRT").slice(0,5).map( (c , index) =>
            <div className={`${classes.asset} row jc-center ai-center py-1 my-2 px-3 rounded-8`} key={index} onClick={()=>selectedCoinHandler(c?.currency1?.code)}>
                <span className={``}>{c?.currency1?.title_fa}</span>
            </div>
        )*/
    }


    const selectedCoinHandler = (coin) => {

        let newGlobal = {...global}

        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
            ["selected_" + global?.selectType]: coin
        }

        setGlobal(newGlobal)

    }

    return (
        content()
    );
};

export default Network;
