import React from 'react';
import classes from './Wallet.module.css';
import {useGetCurrencies, useGetDashboard, useGetUserWallet} from "../../../../../../query";
import Coin from "../Coin/Coin";
import WalletCard from "../WalletCard/WalletCard";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";

const Wallet = () => {

    const {t} = useTranslation();

    const {global} = useGlobal();

    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError} = useGetUserWallet()


    /*console.log("userWalletData", userWalletData)*/

    const content = () => {
        /* if (isLoading) {
             return <span className={`my-10`}>{t("loading")}</span>
         }*/
        if (userWalletIsLoading) {
            return <div className={`width-100 column jc-center ai-center height-100 my-1`}>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
            </div>
        }
        if (userWalletError) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (Object.keys(userWalletData).length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }
        /*return data?.map( (c , index) =>
            <Coin data={c} key={index}/>
        )*/

        /*const newObject = [];
        Object.keys(data).forEach((key) => {
            newObject.push( <Coin data={data[key]} key={key}/>)
        });
        return newObject;*/

        return Object.keys(global?.currencies).map(key => {
            return <WalletCard data={global?.currencies[key]}
                wallet={userWalletData[key]}
                               key={key}
                 />
        })


    }

    return (
        <div className={`width-86 ${classes.container} mt-4 mb-4 column jc-center ai-center rounded-8`}>
            {content()}
        </div>
    );
};

export default Wallet;
