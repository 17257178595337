import React from 'react';
import classes from './Message.module.css';
import moment from "moment-jalaali";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";




const Message = ({data}) => {
    const {t} = useTranslation();
    const user = data?.user_id === data?.from
    const BASE_URL = window.env.REACT_APP_API_BASE_URL;



    const copyToClipboard = (e, value) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success(t("copy"));
    }


    /*console.log("user", user)*/

    return (
        <div className={`${classes.container} width-100 flex ai-center ${ user ? 'js-start' : 'jc-end'} my-05`}
             onDoubleClick={(e) => copyToClipboard(e, data?.text)}
        >
            <div className={`${classes.content} column rounded-8 px-4 py-1 ${ user ? 'bg-light-blue-alpha' : 'bg-light-gray'}`}>
                {data?.file1 && <img src={`${data?.file1}`} className={`${classes.file} width-100 rounded-8 mb-2`} alt=""/>}
                <span className={`fs-01`}>{data?.text}</span>
                <span className={`fs-0-9 mt-1 ${user ? 'text-end' : 'text-start'}`}>{moment(data?.created_at).format("jYYYY/jMM/jDD , HH:mm:ss")}</span>
            </div>
        </div>
    );
};

export default Message;
