import React, {useState} from 'react';
import classes from './AddCard.module.css'
import {images} from "../../../../../../assets/images/images";
import {Trans, useTranslation} from "react-i18next";
import TextInput from "../../../../../../components/TextInput/TextInput";
import Icon from "../../../../../../components/Icon/Icons";
import Button from "../../../../../../components/Button/Button";
import Loading from "../../../../../../components/Loading/Loading";
import {cardHandler} from "../../../../../../utils/card";
import {createAccount} from "../../../../../../api/auth";
import toast from "react-hot-toast";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetBankAccounts} from "../../../../../../query";

const AddCard = () => {

    const {t} = useTranslation();
    const {auth} = useAuth();

    const [loading, setLoading] = useState(false)

    const {refetch} = useGetBankAccounts()

    const [input, setInput] = useState({
        card: {value: "", error: []},
    });

    const currentCard = input?.card?.value.replace(/\W/gi, '').slice(0,6)

    /*const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t("LinkedBankAccounts."+ e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }


        if (e.target.dataset?.name === "card") {
            console.log("e", e.target.value)

            let hash = e.target.value.split("-").join("");
            if (hash?.length > 0 ) {
                inputVal = hash.match(new RegExp('.{1,4}', 'g')).join("-");
            }

        }



        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }*/

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const cardInputHandler = (e) =>{
        let errorMessage = []
        let inputVal = e.target.value.replace(/[^0-9]+/g, "");

        if(inputVal.length < e.target.dataset.min){
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t("LinkedBankAccounts."+ e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        if (inputVal?.length > 0 ) {
            inputVal = inputVal.match(new RegExp('.{1,4}', 'g')).join(" - ");
        }


        let prevState = {
            ...input,
            card: {value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        setLoading(true)


        const cardParam = {
            "type": "CARD",
            "bank": cardHandler(currentCard),
            "number": input?.card?.value.replace(/\W/gi, '')
        }

        createAccount(cardParam, auth?.token)
            .then(async (res) => {
                toast.success(<Trans
                    i18nKey="LinkedBankAccounts.success"
                    values={{
                        name: t("LinkedBankAccounts.cardNumber"),
                    }}
                />)

                setInput({
                    card: {value: "", error: []},
                })
                refetch()
            }).catch(err => {

            toast.error(err?.response?.data?.message)

        }).finally(() => {

            setLoading(false)

        })


    }

    const pasteFromClipboard = (e) => {
        e.preventDefault();
        if (navigator.clipboard !== undefined) {
            navigator.clipboard.readText()
                .then(text => {


                    let errorMessage = []
                    let inputVal = text.replace(/[^0-9]+/g, "");

                    if(inputVal.length < e.target.dataset.min){
                        errorMessage.push(<Trans
                            i18nKey="Login.minInput"
                            values={{
                                name: t("LinkedBankAccounts."+ e.target.dataset.name),
                                min: e.target.dataset.min
                            }}
                        />)
                    }
                    if (inputVal?.length > 0 ) {
                        inputVal = inputVal.match(new RegExp('.{1,4}', 'g')).join(" - ");
                    }


                    let prevState = {
                        ...input,
                        card: {value: inputVal, error: errorMessage}
                    }
                    setInput(prevState)



                    //console.log('Pasted content: ', text);
                    /*setInput({...input, card: {value: text, error: []}})*/
                    //{toast.success(t('paste'));
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                });
        }
        else {
            /*toast.error(t('error'))*/
            return false
        };

    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

   /* const cardSeprator = (e,ele) => {
        if(ele.value.length<19){
            ele.value= ele.value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
            return true;
        }else{
            return false;
        }
    }*/


    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} width-100 column jc-center ai-center`}>

            <div className={`${classes.logo}`}
                 style={{backgroundImage: `url("${images[cardHandler(currentCard)]}")`}}
            />

            <span className={`mt-1 mb-2 font-weight-bold`}>
                <Trans
                    i18nKey="LinkedBankAccounts.bankName"
                    values={{
                        name: currentCard.length >= 6 ? t("IBAN."+  cardHandler(currentCard)) : "---",
                    }}
                />
            </span>

            <TextInput
                value={input.card.value}
                label={t('LinkedBankAccounts.cardNumber')}
                id="card"
                labelFor="card"
                //placeholder={t('Login.mobilePh')}
                data-name="card"
                data-type="input"
                data-min={16}
                maxLength="25"
                onchange={cardInputHandler}


                /*onKeyPress={(e)=>cardSeprator(e)}*/


                alerts={input.card.error}
                inputClass={`width-100 mt-1 mb-3`}
                type="text"
                icon={
                    <Icon
                        iconName={`icon-paste-1 fs-03 flex cursor-pointer`}
                        onClick={(e) => pasteFromClipboard(e)}
                    />
                }
            />


            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading}
            />



        </form>
    );
};

export default AddCard;
