import React, {useState} from 'react';
import classes from './Login.module.css';
import {Trans, useTranslation} from "react-i18next";
import {images} from "../../../../assets/images/images";
import Button from "../../../../components/Button/Button";
import TextInput from "../../../../components/TextInput/TextInput";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import {requestOTP} from "../../../../api/auth";
import useAuth from "../../../../Hooks/useAuth";
import Loading from "../../../../components/Loading/Loading";
import toast from "react-hot-toast";
import useGlobal from "../../../../Hooks/useGlobal";

const Login = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();
    const {global, setGlobal} = useGlobal();

    const [loading, setLoading] = useState(false)


    const [input, setInput] = useState({
        mobile: {value: "", error: []},
    });


    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {

            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        setLoading(true)

        requestOTP(input?.mobile?.value)
            .then(async (res) => {

                setAuth({...auth,
                    mobile: input?.mobile?.value,
                })

                setGlobal({...global,
                    otpLock: new Date().getTime() + 2 * 60 * 1000,
                })

                navigate(RoutesName.Otp)

            }).catch(err => {

            setAuth({...auth,
                mobile: input?.mobile?.value,
            })

            if (err?.response?.data?.data?.needPassword) {
                navigate(RoutesName.Password)
            }
            else {
                toast.error(err?.response?.data?.message)
            }

        }).finally(() => {

            setLoading(false)

        })

        /*return navigate(RoutesName.Otp)*/

    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
      return t('submit')
    }


    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} height-100 column jc-between ai-center py-3 px-5`}>

            <div className={`column jc-center ai-center width-100 mt-1`}>
                <img src={images.lightLogo} className={`${classes.logo}`} alt=""/>
                <span className={`fs-02 mt-3 font-weight-bold`}>{t("Login.description")}</span>
            </div>

            <TextInput
                value={input.mobile.value}
                type="text"
                id="mobile"
                labelFor="mobile"
                label={t('mobile')}
                inputmode="numeric"
                //placeholder={t('Login.mobilePh')}
                data-name="mobile"
                data-type="input"
                data-min={11}
                //maxLength="10"
                onchange={(e) => inputHandler(e)}
                alerts={input.mobile.error}
                inputClass={`width-85 mb-10`}
                maxLength="11"
            />

            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading}
            />

        </form>
    );
};

export default Login;
