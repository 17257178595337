import React, {useEffect, useState} from 'react';
import classes from './Register.module.css';
import {images} from "../../../../assets/images/images";
import TextInput from "../../../../components/TextInput/TextInput";
import Button from "../../../../components/Button/Button";
import {Trans, useTranslation} from "react-i18next";
import {Navigate, useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import ScrollBar from "../../../../components/ScrollBar";
import {isValidNationalCode, validateEmail} from "../../../../utils/utils";

import Icon from "../../../../components/Icon/Icons";
import Loading from "../../../../components/Loading/Loading";
import toast from "react-hot-toast";
import {register, validateOTP} from "../../../../api/auth";
import useAuth from "../../../../Hooks/useAuth";

const Register = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();

    const [loading, setLoading] = useState(false)

    const [hasCard, setHasCard] = useState(true)

    const [input, setInput] = useState({
        firstname: {value: "", error: []},
        lastname: {value: "", error: []},
        identityID: {value: "", error: []},
        email: {value: "", error: []},
        password: {value: "", error: []},
        confirmPassword: {value: "", error: []},
    });

    const [isInputVisible, setIsInputVisible] = useState({
        password: false,
        confirmPassword: false,
    });

    useEffect(()=>{


        if (!auth?.InitialToken) {
            localStorage.clear()
            navigate(RoutesName.Login)
        }

    },[auth?.InitialToken])

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value

        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }


        if (e.target.dataset?.type === "identityID" && hasCard && !isValidNationalCode(inputVal)) {

            inputVal = inputVal.replace(/[^0-9]+/g, "")
            errorMessage.push(t('Register.wrongIDCard'))
        }

        if (e.target.dataset?.type === "email" && !validateEmail(e.target.value)) {
            errorMessage.push(t('wrongEmail'))
        }

        if (e.target.dataset?.name === "confirmPassword" && e.target.value !== input.password.value) {
            errorMessage.push([t('wrongPasswordConfirmation')])
        }


        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }

        if (e.target.dataset?.name === "password") {
            prevState.confirmPassword.error = (e.target.value === input.confirmPassword.value || input.confirmPassword.value.length === 0) ? [] : [t('wrongPasswordConfirmation')]
        }

        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        setLoading(true)


        const profileParam = {
            "first_name": input.firstname.value,
            "last_name": input.lastname.value,
            "identity_id": input.identityID.value,
            "email": input.email.value,
            "password": input.password.value,
            "password_confirmation": input.confirmPassword.value,
            "has_card": hasCard
        }

        register(profileParam, auth?.InitialToken)
            .then(async (res) => {


                setAuth({...auth,
                    token: auth?.InitialToken,
                })

                navigate(RoutesName.Home)

            }).catch(err => {


            toast.error(err?.response?.data?.message)

            /*   if (err?.response?.data?.data?.needPassword) {
                   navigate(RoutesName.Password)
               }
               else {
                   toast.error(err?.response?.data?.message)
               }*/

        }).finally(() => {

            setLoading(false)

        })


        /*navigate(RoutesName.Home)*/



    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} height-100 column jc-between ai-center py-3 px-5`}>
            <ScrollBar>
            <div className={`column jc-center ai-center width-100`}>

                <span className={`fs-01 `}>{t("Register.description")}</span>
            </div>

            <div className={`column jc-center ai-center width-100 my-3`}>

                <TextInput
                    value={input.firstname.value}
                    type="text"
                    label={t('firstname')}
                    id="firstname"
                    labelFor="firstname"
                    //placeholder={t('Login.mobilePh')}
                    data-name="firstname"
                    data-type="input"
                    data-min={2}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.firstname.error}
                    inputClass={`width-85 my-1`}
                />
                <TextInput
                    value={input.lastname.value}
                    type="text"
                    label={t('lastname')}
                    id="lastname"
                    labelFor="lastname"
                    //placeholder={t('Login.mobilePh')}
                    data-name="lastname"
                    data-type="input"
                    data-min={2}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.lastname.error}
                    inputClass={`width-85 my-1`}
                />

                <div className={`width-85  px-1`}>



                    <div className="width-100  row jc-start ai-center">
                        <input type="checkbox" className={`${classes.checkBox} rounded-8`}
                               checked={!hasCard}
                               onChange={()=>setHasCard(prevState => !prevState)}
                        />
                        <span className={`mt-05`}>{t("Register.noIDCard")}</span>
                    </div>



                </div>
                <div className={`width-85 mb-1 px-1`}>



                    <div className="width-100  row jc-start ai-center">
                        <span className={`mt-05`}>{ hasCard ? t('Register.IdentityIDWithCard') : t('Register.IdentityIDWithOutCard')}</span>
                    </div>



                </div>


                <TextInput
                    value={input.identityID.value}
                    //type={hasCard ? "number" : "text"}
                    type={ "text"}
                    maxLength={hasCard ? 10 : null}
                    //maxLength="10"
                    label={t('identityID')}
                    id="identityID"
                    labelFor="identityID"
                    // subLabel={ hasCard ? t('Register.IdentityIDWithCard') : t('Register.IdentityIDWithOutCard')}
                    //placeholder={t('Login.mobilePh')}
                    data-name="identityID"
                    data-type="identityID"
                    data-min={hasCard ? null : 5}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.identityID.error}
                    inputClass={`width-85 my-1`}

                    // placeholder=""
                />

               {/* { hasIdCard && <TextInput
                    value={input.nationalCode.value}
                    type="text"
                    label={t('nationalCode')}
                    //placeholder={t('Login.mobilePh')}
                    data-name="nationalCode"
                    data-type="input"
                    data-min={2}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.nationalCode.error}
                    inputClass={`width-85 mt-1`}

                    // placeholder=""
                />}



                { !hasIdCard && <div className={`width-85 mt-2 mb-4`}>

                    <div className={`font-weight-bold px-1`}>{t("Register.enterIdentityID")}</div>

                    <TextInput
                        value={input.nationalCode.value}
                        type="text"
                        //label={t('nationalCode')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="identityID"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.nationalCode.error}
                        inputClass={`width-100 mt-1`}

                        // placeholder=""
                    />

                </div>}*/}



                <TextInput
                    value={input.email.value}
                    type="text"
                    label={t('email')}
                    id="email"
                    labelFor="email"
                    //placeholder={t('Login.mobilePh')}
                    data-name="email"
                    data-type="email"
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.email.error}
                    inputClass={`width-85 my-1`}
                />
                <TextInput
                    value={input.password.value}
                    label={t('password')}
                    id="password"
                    labelFor="password"
                    //placeholder={t('Login.mobilePh')}
                    data-name="password"
                    data-type="input"
                    data-min={8}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.password.error}
                    inputClass={`width-85 my-1`}
                    autoComplete="new-password"
                    type={isInputVisible.password ? "text" : "password"}
                    icon={
                        <Icon
                            iconName={`${isInputVisible.password ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                            onClick={() => setIsInputVisible({
                                ...isInputVisible,
                                password: !isInputVisible.password
                            })}
                        />
                    }
                />
                <TextInput
                    value={input.confirmPassword.value}
                    label={t('confirmPassword')}
                    id="confirmPassword"
                    labelFor="confirmPassword"
                    //placeholder={t('Login.mobilePh')}
                    data-name="confirmPassword"
                    data-type="input"
                    //data-min={2}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.confirmPassword.error}
                    inputClass={`width-85 my-1`}
                    autoComplete="new-password"
                    type={isInputVisible.confirmPassword ? "text" : "password"}
                    icon={
                        <Icon
                            iconName={`${isInputVisible.confirmPassword ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                            onClick={() => setIsInputVisible({
                                ...isInputVisible,
                                confirmPassword: !isInputVisible.confirmPassword
                            })}
                        />
                    }

                />

            </div>
            </ScrollBar>
            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}

                disabled={loading}
            />

        </form>
    );
};

export default Register;
