import React, {useEffect, useState} from 'react';
import classes from './Exchange.module.css';
import {Trans, useTranslation} from "react-i18next";
import useAuth from "../../../../Hooks/useAuth";
import TextInput from "../../../../components/TextInput/TextInput";
import Icons from "../../../../components/Icon/Icons";
import Button from "../../../../components/Button/Button";
import ScrollBar from "../../../../components/ScrollBar";
import {useGetUserWallet} from "../../../../query";
import {BN, parsePriceString} from "../../../../utils/utils";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import toast from "react-hot-toast";
import {reserveOrder} from "../../../../api/exchange";
import Loading from "../../../../components/Loading/Loading";
import useGlobal from "../../../../Hooks/useGlobal";

const Exchange = () => {

    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError} = useGetUserWallet()


    const {t} = useTranslation();

    const {auth} = useAuth();
    const {global, setGlobal} = useGlobal();

    const [loading, setLoading] = useState(false)



    const [input, setInput] = useState({
        base: {value: "", error: []},
        quote: {value: "", error: []},
        availableAmount: 0,
        exchangeRate: 0,
        payAmountPrecision: 2,
        receiveAmountPrecision: 2,
    });



    useEffect(()=>{

        setAlert({
            submit: false,
            payAmount: null,
            receiveAmount: null,
        })

        let newInputData = {...input}

        if (global?.selected_exchange_base === global?.selected_exchange_quote) {
            setGlobal({
                ...global,
                selected_exchange_base: global?.selected_exchange_base,
                selected_exchange_quote: null,
            })

            newInputData = {...newInputData,
                base : { value: global?.selected_exchange_base, error: []},
                quote : { value: "", error: []},
                availableAmount: userWalletIsLoading ? 0 : userWalletData[global?.selected_exchange_base]?.balance,

                exchangeRate: global?.currencies[global?.selected_exchange_base]?.routes[global?.selected_exchange_quote] || 0,
                payAmountPrecision: global?.currencies[global?.selected_exchange_base]?.precision,
                receiveAmountPrecision: global?.currencies[global?.selected_exchange_quote]?.precision,

            }

        } else {
            if (global?.selected_exchange_base) {
                newInputData = {...newInputData,
                    base : { value: global?.selected_exchange_base, error: []},
                    availableAmount: userWalletIsLoading ? 0 : userWalletData[global?.selected_exchange_base]?.balance
                }
            }
            if (global?.selected_exchange_quote) newInputData.quote = { value: global?.selected_exchange_quote, error: []}

            newInputData = {...newInputData,
                exchangeRate: global?.currencies[global?.selected_exchange_base]?.routes[global?.selected_exchange_quote] || 0,
                payAmountPrecision: global?.currencies[global?.selected_exchange_base]?.precision,
                receiveAmountPrecision: global?.currencies[global?.selected_exchange_quote]?.precision,}
        }

        setInput(newInputData)

        setExchange({
            payAmount: new BN(0),
            receiveAmount: new BN(0),
        })



    },[global?.selectType])



    const [alert, setAlert] = useState({
        submit: false,
        payAmount: null,
        receiveAmount: null,
    });



    const [exchange, setExchange] = useState({

        payAmount: new BN(0),
        receiveAmount: new BN(0),


    });



    const reversePair = () => {

        if ((input?.base?.value === "") || (input?.quote?.value === "")) return false;

        else {
            setGlobal({
                ...global,
                selected_exchange_base: global?.selected_exchange_quote,
                selected_exchange_quote:  global?.selected_exchange_base,
                selectType: global?.selected_exchange_quote
            })

            setAlert({
                submit: false,
                payAmount: null,
                receiveAmount: null,
            })
        }

    }


    const inputChangeHandler = (value, key) => {

        value = parsePriceString(value);

        let availableAmount = new BN(input?.availableAmount)
        let exchangeRate = new BN(input?.exchangeRate)


        const newAlert = {...alert}
        newAlert.payAmount = null
        newAlert.receiveAmount = null

        const minReceiveAmount = new BN(10).pow(-(input?.receiveAmountPrecision))

        switch (key) {

            case "payAmount":
                const payAmount = new BN(value);

                if (payAmount.isGreaterThan(availableAmount) ) {
                    newAlert.payAmount = t("noInventory")
                }

                const calculateReceiveAmount = payAmount.multipliedBy(exchangeRate)

                if (calculateReceiveAmount.isLessThan(minReceiveAmount) && !payAmount.isZero()) {
                    newAlert.receiveAmount = <Trans
                        i18nKey="Exchange.minReceiveAmount"
                        values={{
                            min: minReceiveAmount,
                            currency: global?.selected_exchange_quote,
                        }}
                    />
                }

                setExchange({
                    ...exchange,
                    payAmount: payAmount,
                    receiveAmount: calculateReceiveAmount,
                });

                break;

            case "receiveAmount":
                const receiveAmount = new BN(value);

                if (receiveAmount.dividedBy(exchangeRate).isGreaterThan(availableAmount)) {
                    newAlert.payAmount = t("noInventory")
                }

                setExchange({
                    ...exchange,
                    payAmount: receiveAmount.dividedBy(exchangeRate),
                    receiveAmount: receiveAmount,
                });
                break;

            default:
        }
        setAlert(newAlert)
    };

    const fillByUserWallet = () => {
        if (input?.availableAmount === undefined) return
        let availableAmount = new BN(input?.availableAmount)
        setExchange({
            ...exchange,
            payAmount: availableAmount,
            receiveAmount: availableAmount.multipliedBy(input?.exchangeRate),
        });
    };


    const submit = async (e) => {
        e.preventDefault();

        setLoading(true)

        const reserveOrderParam = {
            "amount": exchange?.payAmount,
            "source": input.base.value,
            "destination": input.quote.value,
        }

        reserveOrder(reserveOrderParam, auth?.token)
            .then(async (res) => {

                const reserveOrderRes = {
                    "destSymbol": res?.data?.data?.destSymbol,
                    "sourceSymbol": res?.data?.data?.sourceSymbol,
                    "sourceAmount": res?.data?.data?.sourceAmount,
                    "guaranteedDestAmount": res?.data?.data?.guaranteedDestAmount,
                    "reserveNumber": res?.data?.data?.reserveNumber,
                }

                setGlobal({
                    ...global,
                    activeActionSheet: {
                        menu: false,
                        select: true,
                    },
                    selectType: "finalize_order",
                    reserveOrderRes: reserveOrderRes,
                })

            }).catch(err => {

            toast.error(err?.response?.data?.message)

        }).finally(() => {

            setLoading(false)

        })

    }


    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }


    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} width-86 m-auto height-100 rounded-8 pt-3 pb-5 column jc-center ai-center`}>
            <ScrollBar>
                <div className={`column jc-between width-100 height-100`}>
                    <div className={`column jc-center ai-center width-100`}>
                        <TextInput
                            value={input.base.value}
                            type="text"
                            label={t('base')}
                            data-name="base"
                            data-type="input"
                            data-min={2}
                            alerts={input.base.error}
                            inputClass={`width-85 mt-1`}
                            select={true}
                            readOnly={true}
                            selectType="exchange_base"
                        />

                        <div className={`width-86 m-auto flex jc-center ai-center`}>
                            <Icons
                                iconName="icon-exchange-arrow flex fs-05"
                                iconClass={`cursor-pointer my-2 ${((input?.base?.value === "") || (input?.quote?.value === "")) && "text-gray"}`}
                                onClick={()=> reversePair()}
                            />
                        </div>

                        <TextInput
                            value={input.quote.value}
                            type="text"
                            label={t('quote')}
                            data-name="quote"
                            data-type="input"
                            data-min={2}
                            alerts={input.quote.error}
                            inputClass={`width-85 mt-1`}
                            select={true}
                            readOnly={true}
                            selectType="exchange_quote"
                        />
                    </div>

                    <div className={`column jc-center ai-center width-100`}>

                        <NumberInput
                            value={exchange.payAmount.toFormat()}
                            alert={alert.payAmount}
                            label={t('payAmount')}
                            onchange={(e) => inputChangeHandler(e.target.value, "payAmount")}
                            maxDecimal={input?.payAmountPrecision}
                            inputClass={`width-85 my-1`}
                            icon={
                                <span>{global?.selected_exchange_base}</span>
                            }
                        />

                        <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 mb-2 position-relative `} onClick={fillByUserWallet}>
                            <span className={`width-40`}>{t("availableBalance")}</span>
                            <div className={`width-60 row jc-end ai-center`}>
                                <span className={`fs-02 font-weight-bold`}>{ input?.availableAmount !== undefined ? new BN(input?.availableAmount).decimalPlaces(((input.base.value !== "") && (input.base.value !== undefined) && (input.base.value !== null))  ? global?.currencies[input?.base?.value]?.precision : 0).toFormat() : "---" } </span>
                                <span className={`mr-1`}>{input?.base?.value && global?.currencies[input?.base?.value]?.alias}</span>
                            </div>
                        </div>

                        <NumberInput
                            value={exchange.receiveAmount.toFormat()}
                            alert={alert.receiveAmount}
                            label={t('receiveAmount')}
                            onchange={(e) => inputChangeHandler(e.target.value, "receiveAmount")}
                            maxDecimal={input?.receiveAmountPrecision}
                            inputClass={`width-85 my-1 mt-2`}
                            icon={
                                <span>{global?.selected_exchange_quote}</span>
                            }
                        />

                        <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 mb-2 position-relative `}>
                            <span className={`width-40`}>{t("rate")}</span>
                            <div className={`width-60 row jc-end ai-center`}>
                                <span className={`fs-02 font-weight-bold`}>{ input?.exchangeRate !== 0 ? new BN(input?.exchangeRate).decimalPlaces(global?.currencies[input?.quote?.value]?.precision).toFormat() : "---" } </span>
                                <span className={`mr-1`}>{input?.exchangeRate !== 0 && global?.currencies[input?.quote?.value]?.alias }</span>
                            </div>
                        </div>

                    </div>

                    <Button
                        type="submit"
                        buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                        buttonTitle={buttonTitleHandler()}

                        disabled={loading}
                    />
                </div>
            </ScrollBar>

        </form>
    );
};

export default Exchange;
