import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";

export const useGetBankAccounts = (config = {}) => {

    const axiosPrivate =  useAxiosPrivate()
    const {auth} = useAuth();

    return useQuery(
        ['accounts', auth?.mobile],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/bank-accounts`)
            return data?.data;
        },
        config);
}