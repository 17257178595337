import React, {useEffect, useState} from 'react';
import classes from './Otp.module.css'
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {images} from "../../../../assets/images/images";
import TextInput from "../../../../components/TextInput/TextInput";
import Button from "../../../../components/Button/Button";
import OtpInput from "react-otp-input";
import * as RoutesName from "../../../../Routes/routes";
import useAuth from "../../../../Hooks/useAuth";
import {requestOTP, validateOTP} from "../../../../api/auth";
import toast from "react-hot-toast";
import Loading from "../../../../components/Loading/Loading";
import Countdown from "react-countdown";
import useGlobal from "../../../../Hooks/useGlobal";
import OtpContainer from "../../../../components/OtpContainer/OtpContainer";

const Otp = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();

    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState({
        otp: {value: "", error: []},
    });

    const submit = async (e) => {


        if (!input.otp.value) {
            return setInput({...input, otp:{...input.otp, error: [`${t("Otp.enterCode")}`] } })
        }

        if (input.otp.value.length < 6) {
            return setInput({...input, otp:{...input.otp, error: [`${t("Otp.otpCodeMin")}`] } })
        }

        setLoading(true)
        validateOTP(auth?.mobile, input?.otp?.value)
            .then(async (res) => {
                setAuth({...auth,
                    InitialToken: res?.data?.data?.access_token,
                })
                navigate(RoutesName.Register)
            }).catch(err => {
            toast.error(err?.response?.data?.message)
        }).finally(() => {
            setLoading(false)
        })
    }




    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

    return (
        <div className={`${classes.container} height-100 column jc-between ai-center py-3 px-5`}>

            {/*<div className={`column jc-center ai-center width-100 mt-15`}>

                <span className={`fs-01 mt-3 mb-1`}>

                    <Trans
                        i18nKey="Otp.text"
                        values={{
                            mobile: auth?.mobile,
                        }}
                    />

                </span>

                <OtpInput
                    value={input.otp.value}
                    onChange={(e) => setInput({...input , otp: {value: e, error: []}})}
                    numInputs={6}
                    separator={<span className={`${classes.otpInputSeprator}`}> - </span>}
                    containerStyle={`direction-ltr ${classes.otpInputContainer}`}
                    inputStyle={`${classes.otpInput}`}
                    shouldAutoFocus="true"
                    isInputNum="true"
                />

                {resendOtpContentHandler()}




                <div className={`fs-0-9 flex row    ${startTimer ? 'text-gray cursor-not-allowed' : 'cursor-pointer hover-text'}`} onClick={()=>resendOtp()}><span className={`ml-025`}>ارسال مجدد کد</span>   { startTimer &&<span className={`mr-025`}>

                    <Countdown

                        onStart={()=>startTimer}
                        date={ Date.now() + 3000 }
                        renderer={props => <div className={` direction-ltr`}> ({props.minutes} : {props.seconds}) </div>}
                        onComplete={() => handleComplete()}
                    />

                </span>}</div>


            </div>


            {input.otp.error.length !== 0 && <span className={`text-red`}>{input.otp.error}</span>}*/}

            <OtpContainer input={input} setInput={setInput} />




            <Button
                type="button"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading}
                onClick={()=>submit()}
            />

        </div>
    );
};

export default Otp;
