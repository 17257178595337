import React from 'react';
import classes from '../CurrencyCard/CurrencyCard.module.css'
import {useGetCurrencies, useGetDashboard} from "../../../../../../query";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import Coin from "../../../../Pages/Home/Module/Coin/Coin";
import CurrencyCard from "../CurrencyCard/CurrencyCard";
import useGlobal from "../../../../../../Hooks/useGlobal";

const Currencies = ({filter, all}) => {


    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();

    /*const {data, isLoading, error} = useGetDashboard()*/

    const showAllHandler = (coin) => {

        let newGlobal = {...global}


        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
            selected_tx_h_coin: null,
        }

        setGlobal(newGlobal)

    }


    const content = () => {
        /*if (isLoading) {
            return <span className={`my-10`}>{t("loading")}</span>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (Object.keys(data).length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }*/
        if (filter) {
            return Object.keys(global?.currencies).filter((f) => f !== filter ).map(key => <CurrencyCard data={global?.currencies[key]} key={key}/>)
        }
        return <>

            {all && <div className={`${classes.asset} row jc-between ai-center py-2 my-2 px-3 rounded-8`} onClick={()=>showAllHandler()}>
                <span>{t("all")}</span>
            </div>}


            {Object.keys(global?.currencies).map(key => <CurrencyCard data={global?.currencies[key]} key={key}/>)}
        </>
    }




    return (
        content()
    );
};

export default Currencies;
