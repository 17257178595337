import React, {useState, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import classes from "./ImageInput.module.css";
import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import Icons from "../Icon/Icons";

const ImageInput = (props) => {
  const {t} = useTranslation();

/*  useEffect(() => {
    ReactTooltip.rebuild();
  });*/

  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps, fileRejections} = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    maxSize: 1000000,
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        props.onchange(acceptedFiles[0]);
        setFiles([
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          }),
        ]);
      } else {
        setFiles([]);
      }
    },
  });

  useEffect(
      () => () => {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      },
      [files],
  );


  const errorMsg = (code) => {
    switch (code) {
      case "file-too-large":
        return t("ImageInput.fileTooLarge");
      case "file-invalid-type":
        return t("ImageInput.fileInvalidType");
      case "too-many-files":
        return t("ImageInput.tooManyFiles");
      default:
        return t("ImageInput.errorMsgDefault");
    }
  };

  const fileRejectionItems = fileRejections.slice(0, 1).map(({errors}) =>
      errors.map((e) => (
          <div className={`row fs-0-6 mt-1`} key={e.code}>
        <span
            onClick={(e) => {
              e.stopPropagation();
            }}
            data-html={true}
            data-place="bottom"
            data-effect="float"
            data-tip={`<span class="column jc-between col-100">${t(
                "ImageInput.iconErrorText",
            )}</span>`}>
          <Icons
              iconName="icon-info-circled text-red fs-01"
              iconClass={`cursor-pointer`}
          />
        </span>

            <span className={`text-right`}>{errorMsg(e.code)}</span>
          </div>
      )),
  );



  return (
      <section className={`${props.zoneCustomClass} my-1`}>
        <div
            {...getRootProps({
              className: `dropzone flex jc-center ai-center text-center position-relative cursor-pointer hover-text ${classes.zone}`,
            })}
            style={{backgroundImage: `url("${files[0]?.preview}")`}}>
          {files[0]?.preview ? (
              <Icons
                  iconName="icon-cancel-circled text-red fs-04"
                  iconClass={`position-absolute ${classes.cancel} cursor-pointer`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFiles([]);
                    props.onchange("");
                  }}
              />
          ) : (
              ""
          )}

          <input {...getInputProps()} />
          {!files[0]?.preview ? (
              <div className={`column`}>
            <span>
              {props.title}{" "}
              {!fileRejectionItems.length > 0 ? t("ImageInput.dropText") : ""}
            </span>
                {fileRejectionItems.length > 0 ? fileRejectionItems : ""}
              </div>
          ) : (
              ""
          )}
        </div>
      </section>
  );
};

export default ImageInput;
