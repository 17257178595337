import React from 'react';
import classes from './NetworkCard.module.css';
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";

const NetworkCard = ({data}) => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const selectedCoinHandler = (network) => {

        let newGlobal = {...global}

        if (newGlobal) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: network
            }
        }
        else {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: null
            }
        }



        setGlobal(newGlobal)

    }

    return (
        <div className={`${classes.asset} row jc-between ai-center py-1 my-2 px-3 rounded-8`} onClick={()=>selectedCoinHandler(data?.currencyImpData?.implementations[0]?.chain?.name)}>
            <span>{data?.currencyImpData?.implementations[0]?.chain?.name ? data?.currencyImpData?.implementations[0]?.chain?.name : "---"}</span>
        </div>
    );
};

export default NetworkCard;
