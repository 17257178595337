import React, {useEffect, useState} from 'react';
import classes from './Withdrawal.module.css';
import {Trans, useTranslation} from "react-i18next";
import ScrollBar from "../../../../components/ScrollBar";
import TextInput from "../../../../components/TextInput/TextInput";
import WithdrawalAddress from "./Module/WithdrawalAddress/WithdrawalAddress";
import useGlobal from "../../../../Hooks/useGlobal";

const Withdrawal = () => {


    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();


    const [input, setInput] = useState({
        asset: {value: "", error: []},
        network: {value: "", error: []},
    });

    useEffect(()=>{

        let newInputData = {...input}

        if (global?.selected_withdrawal_coin) {
            newInputData = {...newInputData,
                asset : { value: global?.selected_withdrawal_coin, error: []},
                network: {value: "", error: []}
            }
            setGlobal({...global,
                selected_withdrawal_network: null
            })
        }

        if (global?.selected_withdrawal_network && (global?.selected_withdrawal_coin === input?.asset?.value)) {
            newInputData = {...newInputData,
                network: {value: global?.selected_withdrawal_network, error: []}
            }
        }

        if (!global?.selected_withdrawal_network) {
            newInputData = {...newInputData,
                network: {value: "", error: []}
            }
        }


        setInput(newInputData)

    },[global?.selectType])

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        /* return navigate(RoutesName.Home)*/

    }

    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} width-86 height-100 rounded-8 m-auto column jc-center ai-center pb-5`}>
            <ScrollBar>
                <div className={`column jc-center ai-center width-100 mt-4 mb-2`}>
                    <TextInput
                        value={input.asset.value}
                        // value={t('currency.'+ input.asset.value)}
                        type="text"
                        label={t('asset')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="asset"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.asset.error}
                        inputClass={`width-85 my-1`}
                        select={true}
                        readOnly={true}
                        selectType="withdrawal_coin"
                    />
                    <TextInput
                        value={input.network.value}
                        // value={t('currency.'+ input.asset.value)}
                        type="text"
                        label={t('network')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="network"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.network.error}
                        inputClass={`width-85 my-1`}
                        select={true}
                        readOnly={true}
                        selectType="withdrawal_network"
                    />
                </div>
                {(input?.asset?.value && input?.network?.value) && <WithdrawalAddress asset={input?.asset?.value} network={input?.network?.value}/>}

            </ScrollBar>

        </form>
    );
};

export default Withdrawal;
