import React, {useState} from 'react';
import classes from './TransactionPassword.module.css';
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {validateEmail} from "../../../../utils/utils";
import Loading from "../../../../components/Loading/Loading";
import ScrollBar from "../../../../components/ScrollBar";
import Button from "../../../../components/Button/Button";
import TextInput from "../../../../components/TextInput/TextInput";
import Icon from "../../../../components/Icon/Icons";
import {updatePassword, updateProfile} from "../../../../api/auth";
import toast from "react-hot-toast";
import useAuth from "../../../../Hooks/useAuth";

const TransactionPassword = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth} = useAuth();


    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState({
        currentPassword: {value: "", error: []},
        newPassword: {value: "", error: []},
        confirmNewPassword: {value: "", error: []},
    });

    const [isInputVisible, setIsInputVisible] = useState({
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
    });


    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t("TransactionPassword." + e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }

        if (e.target.dataset?.name === "confirmNewPassword" && e.target.value !== input.newPassword.value) {
            errorMessage.push([t('TransactionPassword.wrongPasswordConfirmation')])
        }


        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }

        if (e.target.dataset?.name === "password") {
            prevState.confirmPassword.error = (e.target.value === input.confirmPassword.value || input.confirmPassword.value.length === 0) ? [] : [t('wrongPasswordConfirmation')]
        }

        setInput(prevState)
    }

    const isFormValid = () => {

        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t("TransactionPassword." + key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }

    const submit = async (e) => {

        e.preventDefault();

        if (!isFormValid()) return false;

        setLoading(true)

        const updatePasswordParam = {
            "current_password": input.currentPassword.value,
            "new_password": input.newPassword.value,
            "new_password_confirmation": input.confirmNewPassword.value,
        }

        updatePassword(updatePasswordParam, auth?.token)
            .then(async (res) => {
                toast.success(t("TransactionPassword.success"))
                setInput({
                    currentPassword: {value: "", error: []},
                    newPassword: {value: "", error: []},
                    confirmNewPassword: {value: "", error: []},
                })
            }).catch(err => {
            toast.error(err?.response?.data?.message)
        }).finally(() => {
            setLoading(false)
        })
    }

    const buttonTitleHandler = () => {
        /*if (isLoading) return t('update')*/
        if (loading) return <Loading type="text"/>
        return t('update')
    }


    return (
        <ScrollBar>
            <form onSubmit={(e)=>submit(e)} className={`${classes.container} column jc-between ai-center py-3 px-5`}>
                <div className={`width-86 column jc-center ai-center`}>
                    <div className={`rounded-8 flex jc-center ai-start ${classes.noteBox} px-5 py-2 mt-2 mb-3`}>
                        <span className={``}>{t("TransactionPassword.content")}</span>
                    </div>



                    <div className={`column jc-center ai-center width-100 my-3`}>

                        <TextInput
                            value={input.currentPassword.value}
                            label={t('TransactionPassword.currentPassword')}
                            //placeholder={t('Login.mobilePh')}
                            data-name="currentPassword"
                            data-type="input"
                            data-min={8}
                            //maxLength="10"
                            onchange={(e) => inputHandler(e)}
                            alerts={input.currentPassword.error}
                            inputClass={`width-85 my-1`}
                            autoComplete="off"
                            type={isInputVisible.currentPassword ? "text" : "password"}
                            icon={
                                <Icon
                                    iconName={`${isInputVisible.currentPassword ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                                    onClick={() => setIsInputVisible({
                                        ...isInputVisible,
                                        currentPassword: !isInputVisible.currentPassword
                                    })}
                                />
                            }
                        />
                        <TextInput
                            value={input.newPassword.value}
                            label={t('TransactionPassword.newPassword')}
                            //placeholder={t('Login.mobilePh')}
                            data-name="newPassword"
                            data-type="input"
                            data-min={8}
                            //maxLength="10"
                            onchange={(e) => inputHandler(e)}
                            alerts={input.newPassword.error}
                            inputClass={`width-85 my-1`}
                            autoComplete="new-password"
                            type={isInputVisible.newPassword ? "text" : "password"}
                            icon={
                                <Icon
                                    iconName={`${isInputVisible.newPassword ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                                    onClick={() => setIsInputVisible({
                                        ...isInputVisible,
                                        newPassword: !isInputVisible.newPassword
                                    })}
                                />
                            }
                        />
                        <TextInput
                            value={input.confirmNewPassword.value}
                            label={t('TransactionPassword.confirmNewPassword')}
                            //placeholder={t('Login.mobilePh')}
                            data-name="confirmNewPassword"
                            data-type="input"
                            //data-min={2}
                            //maxLength="10"
                            onchange={(e) => inputHandler(e)}
                            alerts={input.confirmNewPassword.error}
                            inputClass={`width-85 my-1`}
                            autoComplete="new-password"
                            type={isInputVisible.confirmNewPassword ? "text" : "password"}
                            icon={
                                <Icon
                                    iconName={`${isInputVisible.confirmNewPassword ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                                    onClick={() => setIsInputVisible({
                                        ...isInputVisible,
                                        confirmNewPassword: !isInputVisible.confirmNewPassword
                                    })}
                                />
                            }

                        />

                    </div>


                </div>

                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                    buttonTitle={buttonTitleHandler()}
                    disabled={loading}
                />
            </form>
        </ScrollBar>
    );
};

export default TransactionPassword;
